// DO NOT EDIT THIS FILE
// This file has been generated by gen-api-models
// eslint-disable sonar/max-union-size
// eslint-disable sonarjs/no-identical-functions

import * as t from "io-ts";

import * as r from "@pagopa/ts-commons/lib/requests";

import { PaymentRequestsGetResponse } from "./PaymentRequestsGetResponse";

import { ProblemJson } from "./ProblemJson";

import { NodeProblemJson404 } from "./NodeProblemJson404";

import { NodeProblemJson409 } from "./NodeProblemJson409";

import { NodeProblemJson502 } from "./NodeProblemJson502";

import { PartyConfigurationFaultPaymentProblemJson } from "./PartyConfigurationFaultPaymentProblemJson";

import { NewTransactionRequest } from "./NewTransactionRequest";

import { NewTransactionResponse } from "./NewTransactionResponse";

import { TransactionInfo } from "./TransactionInfo";

import { RequestAuthorizationRequest } from "./RequestAuthorizationRequest";

import { RequestAuthorizationResponse } from "./RequestAuthorizationResponse";

import { PaymentMethodsResponse } from "./PaymentMethodsResponse";

import { PaymentMethodResponse } from "./PaymentMethodResponse";

import { CalculateFeeRequest } from "./CalculateFeeRequest";

import { CalculateFeeResponse } from "./CalculateFeeResponse";

import { CreateSessionResponse } from "./CreateSessionResponse";

import { SessionPaymentMethodResponse } from "./SessionPaymentMethodResponse";

import { CartRequest } from "./CartRequest";

/****************************************************************
 * getPaymentRequestInfo
 */

// Request type definition
export type GetPaymentRequestInfoT = r.IGetApiRequestType<
  { readonly rpt_id: string; readonly recaptchaResponse: string },
  never,
  never,
  | r.IResponseType<200, PaymentRequestsGetResponse, never>
  | r.IResponseType<400, ProblemJson, never>
  | r.IResponseType<404, NodeProblemJson404, never>
  | r.IResponseType<409, NodeProblemJson409, never>
  | r.IResponseType<502, NodeProblemJson502, never>
  | r.IResponseType<503, PartyConfigurationFaultPaymentProblemJson, never>
>;

export const getPaymentRequestInfoDefaultResponses = {
  200: PaymentRequestsGetResponse,
  400: ProblemJson,
  404: NodeProblemJson404,
  409: NodeProblemJson409,
  502: NodeProblemJson502,
  503: PartyConfigurationFaultPaymentProblemJson
};

export type GetPaymentRequestInfoResponsesT<
  A0 = PaymentRequestsGetResponse,
  C0 = PaymentRequestsGetResponse,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = NodeProblemJson404,
  C2 = NodeProblemJson404,
  A3 = NodeProblemJson409,
  C3 = NodeProblemJson409,
  A4 = NodeProblemJson502,
  C4 = NodeProblemJson502,
  A5 = PartyConfigurationFaultPaymentProblemJson,
  C5 = PartyConfigurationFaultPaymentProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  404: t.Type<A2, C2>;
  409: t.Type<A3, C3>;
  502: t.Type<A4, C4>;
  503: t.Type<A5, C5>;
};

export function getPaymentRequestInfoDecoder<
  A0 = PaymentRequestsGetResponse,
  C0 = PaymentRequestsGetResponse,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = NodeProblemJson404,
  C2 = NodeProblemJson404,
  A3 = NodeProblemJson409,
  C3 = NodeProblemJson409,
  A4 = NodeProblemJson502,
  C4 = NodeProblemJson502,
  A5 = PartyConfigurationFaultPaymentProblemJson,
  C5 = PartyConfigurationFaultPaymentProblemJson
>(
  overrideTypes:
    | Partial<
        GetPaymentRequestInfoResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<400, A1, never>
  | r.IResponseType<404, A2, never>
  | r.IResponseType<409, A3, never>
  | r.IResponseType<502, A4, never>
  | r.IResponseType<503, A5, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getPaymentRequestInfoDefaultResponses as unknown) as GetPaymentRequestInfoResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, never>(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        never
      >(400, type[400])) as r.ResponseDecoder<r.IResponseType<400, A1, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A2, never>>;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, never>(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        never
      >(409, type[409])) as r.ResponseDecoder<r.IResponseType<409, A3, never>>;

  const d502 = (type[502].name === "undefined"
    ? r.constantResponseDecoder<undefined, 502, never>(502, undefined)
    : r.ioResponseDecoder<
        502,
        typeof type[502]["_A"],
        typeof type[502]["_O"],
        never
      >(502, type[502])) as r.ResponseDecoder<r.IResponseType<502, A4, never>>;

  const d503 = (type[503].name === "undefined"
    ? r.constantResponseDecoder<undefined, 503, never>(503, undefined)
    : r.ioResponseDecoder<
        503,
        typeof type[503]["_A"],
        typeof type[503]["_O"],
        never
      >(503, type[503])) as r.ResponseDecoder<r.IResponseType<503, A5, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d404),
        d409
      ),
      d502
    ),
    d503
  );
}

// Decodes the success response with the type defined in the specs
export const getPaymentRequestInfoDefaultDecoder = () =>
  getPaymentRequestInfoDecoder();

/****************************************************************
 * newTransaction
 */

// Request type definition
export type NewTransactionT = r.IPostApiRequestType<
  {
    readonly recaptchaResponse: string;
    readonly body: NewTransactionRequest | ReadableStream<Uint8Array> | Buffer;
  },
  "Content-Type",
  never,
  | r.IResponseType<200, NewTransactionResponse, never>
  | r.IResponseType<400, ProblemJson, never>
  | r.IResponseType<404, NodeProblemJson404, never>
  | r.IResponseType<409, NodeProblemJson409, never>
  | r.IResponseType<502, NodeProblemJson502, never>
  | r.IResponseType<503, PartyConfigurationFaultPaymentProblemJson, never>
>;

export const newTransactionDefaultResponses = {
  200: NewTransactionResponse,
  400: ProblemJson,
  404: NodeProblemJson404,
  409: NodeProblemJson409,
  502: NodeProblemJson502,
  503: PartyConfigurationFaultPaymentProblemJson
};

export type NewTransactionResponsesT<
  A0 = NewTransactionResponse,
  C0 = NewTransactionResponse,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = NodeProblemJson404,
  C2 = NodeProblemJson404,
  A3 = NodeProblemJson409,
  C3 = NodeProblemJson409,
  A4 = NodeProblemJson502,
  C4 = NodeProblemJson502,
  A5 = PartyConfigurationFaultPaymentProblemJson,
  C5 = PartyConfigurationFaultPaymentProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  404: t.Type<A2, C2>;
  409: t.Type<A3, C3>;
  502: t.Type<A4, C4>;
  503: t.Type<A5, C5>;
};

export function newTransactionDecoder<
  A0 = NewTransactionResponse,
  C0 = NewTransactionResponse,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = NodeProblemJson404,
  C2 = NodeProblemJson404,
  A3 = NodeProblemJson409,
  C3 = NodeProblemJson409,
  A4 = NodeProblemJson502,
  C4 = NodeProblemJson502,
  A5 = PartyConfigurationFaultPaymentProblemJson,
  C5 = PartyConfigurationFaultPaymentProblemJson
>(
  overrideTypes:
    | Partial<
        NewTransactionResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4, A5, C5>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<400, A1, never>
  | r.IResponseType<404, A2, never>
  | r.IResponseType<409, A3, never>
  | r.IResponseType<502, A4, never>
  | r.IResponseType<503, A5, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((newTransactionDefaultResponses as unknown) as NewTransactionResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, never>(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        never
      >(400, type[400])) as r.ResponseDecoder<r.IResponseType<400, A1, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A2, never>>;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, never>(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        never
      >(409, type[409])) as r.ResponseDecoder<r.IResponseType<409, A3, never>>;

  const d502 = (type[502].name === "undefined"
    ? r.constantResponseDecoder<undefined, 502, never>(502, undefined)
    : r.ioResponseDecoder<
        502,
        typeof type[502]["_A"],
        typeof type[502]["_O"],
        never
      >(502, type[502])) as r.ResponseDecoder<r.IResponseType<502, A4, never>>;

  const d503 = (type[503].name === "undefined"
    ? r.constantResponseDecoder<undefined, 503, never>(503, undefined)
    : r.ioResponseDecoder<
        503,
        typeof type[503]["_A"],
        typeof type[503]["_O"],
        never
      >(503, type[503])) as r.ResponseDecoder<r.IResponseType<503, A5, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d404),
        d409
      ),
      d502
    ),
    d503
  );
}

// Decodes the success response with the type defined in the specs
export const newTransactionDefaultDecoder = () => newTransactionDecoder();

/****************************************************************
 * getTransactionInfo
 */

// Request type definition
export type GetTransactionInfoT = r.IGetApiRequestType<
  { readonly bearerAuth: string; readonly transactionId: string },
  "Authorization",
  never,
  | r.IResponseType<200, TransactionInfo, never>
  | r.IResponseType<400, ProblemJson, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<404, ProblemJson, never>
  | r.IResponseType<504, ProblemJson, never>
>;

export const getTransactionInfoDefaultResponses = {
  200: TransactionInfo,
  400: ProblemJson,
  401: t.undefined,
  404: ProblemJson,
  504: ProblemJson
};

export type GetTransactionInfoResponsesT<
  A0 = TransactionInfo,
  C0 = TransactionInfo,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = ProblemJson,
  C4 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
  504: t.Type<A4, C4>;
};

export function getTransactionInfoDecoder<
  A0 = TransactionInfo,
  C0 = TransactionInfo,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = ProblemJson,
  C4 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetTransactionInfoResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<400, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<404, A3, never>
  | r.IResponseType<504, A4, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getTransactionInfoDefaultResponses as unknown) as GetTransactionInfoResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, never>(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        never
      >(400, type[400])) as r.ResponseDecoder<r.IResponseType<400, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  const d504 = (type[504].name === "undefined"
    ? r.constantResponseDecoder<undefined, 504, never>(504, undefined)
    : r.ioResponseDecoder<
        504,
        typeof type[504]["_A"],
        typeof type[504]["_O"],
        never
      >(504, type[504])) as r.ResponseDecoder<r.IResponseType<504, A4, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d401),
      d404
    ),
    d504
  );
}

// Decodes the success response with the type defined in the specs
export const getTransactionInfoDefaultDecoder = () =>
  getTransactionInfoDecoder();

/****************************************************************
 * requestTransactionUserCancellation
 */

// Request type definition
export type RequestTransactionUserCancellationT = r.IDeleteApiRequestType<
  { readonly bearerAuth: string; readonly transactionId: string },
  "Authorization",
  never,
  | r.IResponseType<202, undefined, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<404, ProblemJson, never>
  | r.IResponseType<409, ProblemJson, never>
  | r.IResponseType<500, ProblemJson, never>
  | r.IResponseType<504, ProblemJson, never>
>;

export const requestTransactionUserCancellationDefaultResponses = {
  202: t.undefined,
  401: t.undefined,
  404: ProblemJson,
  409: ProblemJson,
  500: ProblemJson,
  504: ProblemJson
};

export type RequestTransactionUserCancellationResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = ProblemJson,
  C2 = ProblemJson,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  202: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  404: t.Type<A2, C2>;
  409: t.Type<A3, C3>;
  500: t.Type<A4, C4>;
  504: t.Type<A5, C5>;
};

export function requestTransactionUserCancellationDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = ProblemJson,
  C2 = ProblemJson,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        RequestTransactionUserCancellationResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<202, A0, never>
  | r.IResponseType<401, A1, never>
  | r.IResponseType<404, A2, never>
  | r.IResponseType<409, A3, never>
  | r.IResponseType<500, A4, never>
  | r.IResponseType<504, A5, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((requestTransactionUserCancellationDefaultResponses as unknown) as RequestTransactionUserCancellationResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 202: overrideTypes } : overrideTypes)
  };

  const d202 = (type[202].name === "undefined"
    ? r.constantResponseDecoder<undefined, 202, never>(202, undefined)
    : r.ioResponseDecoder<
        202,
        typeof type[202]["_A"],
        typeof type[202]["_O"],
        never
      >(202, type[202])) as r.ResponseDecoder<r.IResponseType<202, A0, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A1, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A2, never>>;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, never>(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        never
      >(409, type[409])) as r.ResponseDecoder<r.IResponseType<409, A3, never>>;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, never>(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        never
      >(500, type[500])) as r.ResponseDecoder<r.IResponseType<500, A4, never>>;

  const d504 = (type[504].name === "undefined"
    ? r.constantResponseDecoder<undefined, 504, never>(504, undefined)
    : r.ioResponseDecoder<
        504,
        typeof type[504]["_A"],
        typeof type[504]["_O"],
        never
      >(504, type[504])) as r.ResponseDecoder<r.IResponseType<504, A5, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d202, d401), d404),
        d409
      ),
      d500
    ),
    d504
  );
}

// Decodes the success response with the type defined in the specs
export const requestTransactionUserCancellationDefaultDecoder = () =>
  requestTransactionUserCancellationDecoder();

/****************************************************************
 * requestTransactionAuthorization
 */

// Request type definition
export type RequestTransactionAuthorizationT = r.IPostApiRequestType<
  {
    readonly bearerAuth: string;
    readonly transactionId: string;
    readonly lang?: string;
    readonly body?:
      | RequestAuthorizationRequest
      | ReadableStream<Uint8Array>
      | Buffer;
  },
  "Content-Type" | "Authorization",
  never,
  | r.IResponseType<200, RequestAuthorizationResponse, never>
  | r.IResponseType<400, ProblemJson, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<404, ProblemJson, never>
  | r.IResponseType<409, ProblemJson, never>
  | r.IResponseType<422, ProblemJson, never>
  | r.IResponseType<500, ProblemJson, never>
  | r.IResponseType<504, ProblemJson, never>
>;

export const requestTransactionAuthorizationDefaultResponses = {
  200: RequestAuthorizationResponse,
  400: ProblemJson,
  401: t.undefined,
  404: ProblemJson,
  409: ProblemJson,
  422: ProblemJson,
  500: ProblemJson,
  504: ProblemJson
};

export type RequestTransactionAuthorizationResponsesT<
  A0 = RequestAuthorizationResponse,
  C0 = RequestAuthorizationResponse,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson,
  A6 = ProblemJson,
  C6 = ProblemJson,
  A7 = ProblemJson,
  C7 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
  409: t.Type<A4, C4>;
  422: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
  504: t.Type<A7, C7>;
};

export function requestTransactionAuthorizationDecoder<
  A0 = RequestAuthorizationResponse,
  C0 = RequestAuthorizationResponse,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = ProblemJson,
  C5 = ProblemJson,
  A6 = ProblemJson,
  C6 = ProblemJson,
  A7 = ProblemJson,
  C7 = ProblemJson
>(
  overrideTypes:
    | Partial<
        RequestTransactionAuthorizationResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6,
          A7,
          C7
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<400, A1, never>
  | r.IResponseType<401, A2, never>
  | r.IResponseType<404, A3, never>
  | r.IResponseType<409, A4, never>
  | r.IResponseType<422, A5, never>
  | r.IResponseType<500, A6, never>
  | r.IResponseType<504, A7, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((requestTransactionAuthorizationDefaultResponses as unknown) as RequestTransactionAuthorizationResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6,
      A7,
      C7
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, never>(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        never
      >(400, type[400])) as r.ResponseDecoder<r.IResponseType<400, A1, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A2, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A3, never>>;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, never>(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        never
      >(409, type[409])) as r.ResponseDecoder<r.IResponseType<409, A4, never>>;

  const d422 = (type[422].name === "undefined"
    ? r.constantResponseDecoder<undefined, 422, never>(422, undefined)
    : r.ioResponseDecoder<
        422,
        typeof type[422]["_A"],
        typeof type[422]["_O"],
        never
      >(422, type[422])) as r.ResponseDecoder<r.IResponseType<422, A5, never>>;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, never>(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        never
      >(500, type[500])) as r.ResponseDecoder<r.IResponseType<500, A6, never>>;

  const d504 = (type[504].name === "undefined"
    ? r.constantResponseDecoder<undefined, 504, never>(504, undefined)
    : r.ioResponseDecoder<
        504,
        typeof type[504]["_A"],
        typeof type[504]["_O"],
        never
      >(504, type[504])) as r.ResponseDecoder<r.IResponseType<504, A7, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(
              r.composeResponseDecoders(d200, d400),
              d401
            ),
            d404
          ),
          d409
        ),
        d422
      ),
      d500
    ),
    d504
  );
}

// Decodes the success response with the type defined in the specs
export const requestTransactionAuthorizationDefaultDecoder = () =>
  requestTransactionAuthorizationDecoder();

/****************************************************************
 * getAllPaymentMethods
 */

// Request type definition
export type GetAllPaymentMethodsT = r.IGetApiRequestType<
  { readonly amount?: number },
  never,
  never,
  r.IResponseType<200, PaymentMethodsResponse, never>
>;

export const getAllPaymentMethodsDefaultResponses = {
  200: PaymentMethodsResponse
};

export type GetAllPaymentMethodsResponsesT<
  A0 = PaymentMethodsResponse,
  C0 = PaymentMethodsResponse
> = {
  200: t.Type<A0, C0>;
};

export function getAllPaymentMethodsDecoder<
  A0 = PaymentMethodsResponse,
  C0 = PaymentMethodsResponse
>(
  overrideTypes:
    | Partial<GetAllPaymentMethodsResponsesT<A0, C0>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<r.IResponseType<200, A0, never>> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getAllPaymentMethodsDefaultResponses as unknown) as GetAllPaymentMethodsResponsesT<
      A0,
      C0
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  return d200;
}

// Decodes the success response with the type defined in the specs
export const getAllPaymentMethodsDefaultDecoder = () =>
  getAllPaymentMethodsDecoder();

/****************************************************************
 * getPaymentMethod
 */

// Request type definition
export type GetPaymentMethodT = r.IGetApiRequestType<
  { readonly id: string },
  never,
  never,
  r.IResponseType<200, PaymentMethodResponse, never>
>;

export const getPaymentMethodDefaultResponses = {
  200: PaymentMethodResponse
};

export type GetPaymentMethodResponsesT<
  A0 = PaymentMethodResponse,
  C0 = PaymentMethodResponse
> = {
  200: t.Type<A0, C0>;
};

export function getPaymentMethodDecoder<
  A0 = PaymentMethodResponse,
  C0 = PaymentMethodResponse
>(
  overrideTypes:
    | Partial<GetPaymentMethodResponsesT<A0, C0>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<r.IResponseType<200, A0, never>> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getPaymentMethodDefaultResponses as unknown) as GetPaymentMethodResponsesT<
      A0,
      C0
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  return d200;
}

// Decodes the success response with the type defined in the specs
export const getPaymentMethodDefaultDecoder = () => getPaymentMethodDecoder();

/****************************************************************
 * calculateFees
 */

// Request type definition
export type CalculateFeesT = r.IPostApiRequestType<
  {
    readonly bearerAuth: string;
    readonly id: string;
    readonly maxOccurrences?: number;
    readonly "x-transaction-id-from-client": string;
    readonly body: CalculateFeeRequest | ReadableStream<Uint8Array> | Buffer;
  },
  "Content-Type" | "Authorization",
  never,
  | r.IResponseType<200, CalculateFeeResponse, never>
  | r.IResponseType<400, ProblemJson, never>
  | r.IResponseType<404, ProblemJson, never>
  | r.IResponseType<500, ProblemJson, never>
>;

export const calculateFeesDefaultResponses = {
  200: CalculateFeeResponse,
  400: ProblemJson,
  404: ProblemJson,
  500: ProblemJson
};

export type CalculateFeesResponsesT<
  A0 = CalculateFeeResponse,
  C0 = CalculateFeeResponse,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = ProblemJson,
  C2 = ProblemJson,
  A3 = ProblemJson,
  C3 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  404: t.Type<A2, C2>;
  500: t.Type<A3, C3>;
};

export function calculateFeesDecoder<
  A0 = CalculateFeeResponse,
  C0 = CalculateFeeResponse,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = ProblemJson,
  C2 = ProblemJson,
  A3 = ProblemJson,
  C3 = ProblemJson
>(
  overrideTypes:
    | Partial<CalculateFeesResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<400, A1, never>
  | r.IResponseType<404, A2, never>
  | r.IResponseType<500, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((calculateFeesDefaultResponses as unknown) as CalculateFeesResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, never>(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        never
      >(400, type[400])) as r.ResponseDecoder<r.IResponseType<400, A1, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A2, never>>;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, never>(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        never
      >(500, type[500])) as r.ResponseDecoder<r.IResponseType<500, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d404),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const calculateFeesDefaultDecoder = () => calculateFeesDecoder();

/****************************************************************
 * createSession
 */

// Request type definition
export type CreateSessionT = r.IPostApiRequestType<
  { readonly id: string; readonly lang?: string },
  "Content-Type",
  never,
  | r.IResponseType<200, CreateSessionResponse, never>
  | r.IResponseType<404, ProblemJson, never>
  | r.IResponseType<502, ProblemJson, never>
>;

export const createSessionDefaultResponses = {
  200: CreateSessionResponse,
  404: ProblemJson,
  502: ProblemJson
};

export type CreateSessionResponsesT<
  A0 = CreateSessionResponse,
  C0 = CreateSessionResponse,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = ProblemJson,
  C2 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  404: t.Type<A1, C1>;
  502: t.Type<A2, C2>;
};

export function createSessionDecoder<
  A0 = CreateSessionResponse,
  C0 = CreateSessionResponse,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = ProblemJson,
  C2 = ProblemJson
>(
  overrideTypes:
    | Partial<CreateSessionResponsesT<A0, C0, A1, C1, A2, C2>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<404, A1, never>
  | r.IResponseType<502, A2, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((createSessionDefaultResponses as unknown) as CreateSessionResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A1, never>>;

  const d502 = (type[502].name === "undefined"
    ? r.constantResponseDecoder<undefined, 502, never>(502, undefined)
    : r.ioResponseDecoder<
        502,
        typeof type[502]["_A"],
        typeof type[502]["_O"],
        never
      >(502, type[502])) as r.ResponseDecoder<r.IResponseType<502, A2, never>>;

  return r.composeResponseDecoders(r.composeResponseDecoders(d200, d404), d502);
}

// Decodes the success response with the type defined in the specs
export const createSessionDefaultDecoder = () => createSessionDecoder();

/****************************************************************
 * getSessionPaymentMethod
 */

// Request type definition
export type GetSessionPaymentMethodT = r.IGetApiRequestType<
  {
    readonly bearerAuth: string;
    readonly id: string;
    readonly orderId: string;
    readonly "x-transaction-id-from-client": string;
  },
  "Authorization",
  never,
  | r.IResponseType<200, SessionPaymentMethodResponse, never>
  | r.IResponseType<401, undefined, never>
  | r.IResponseType<404, ProblemJson, never>
  | r.IResponseType<500, ProblemJson, never>
>;

export const getSessionPaymentMethodDefaultResponses = {
  200: SessionPaymentMethodResponse,
  401: t.undefined,
  404: ProblemJson,
  500: ProblemJson
};

export type GetSessionPaymentMethodResponsesT<
  A0 = SessionPaymentMethodResponse,
  C0 = SessionPaymentMethodResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = ProblemJson,
  C2 = ProblemJson,
  A3 = ProblemJson,
  C3 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  404: t.Type<A2, C2>;
  500: t.Type<A3, C3>;
};

export function getSessionPaymentMethodDecoder<
  A0 = SessionPaymentMethodResponse,
  C0 = SessionPaymentMethodResponse,
  A1 = undefined,
  C1 = undefined,
  A2 = ProblemJson,
  C2 = ProblemJson,
  A3 = ProblemJson,
  C3 = ProblemJson
>(
  overrideTypes:
    | Partial<GetSessionPaymentMethodResponsesT<A0, C0, A1, C1, A2, C2, A3, C3>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<401, A1, never>
  | r.IResponseType<404, A2, never>
  | r.IResponseType<500, A3, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getSessionPaymentMethodDefaultResponses as unknown) as GetSessionPaymentMethodResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, never>(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        never
      >(401, type[401])) as r.ResponseDecoder<r.IResponseType<401, A1, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A2, never>>;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, never>(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        never
      >(500, type[500])) as r.ResponseDecoder<r.IResponseType<500, A3, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d404),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getSessionPaymentMethodDefaultDecoder = () =>
  getSessionPaymentMethodDecoder();

/****************************************************************
 * GetCarts
 */

// Request type definition
export type GetCartsT = r.IGetApiRequestType<
  { readonly id_cart: string },
  never,
  never,
  | r.IResponseType<200, CartRequest, never>
  | r.IResponseType<400, ProblemJson, never>
  | r.IResponseType<404, ProblemJson, never>
  | r.IResponseType<500, ProblemJson, never>
  | r.IResponseType<504, ProblemJson, never>
>;

export const getCartsDefaultResponses = {
  200: CartRequest,
  400: ProblemJson,
  404: ProblemJson,
  500: ProblemJson,
  504: ProblemJson
};

export type GetCartsResponsesT<
  A0 = CartRequest,
  C0 = CartRequest,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = ProblemJson,
  C2 = ProblemJson,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = ProblemJson,
  C4 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  404: t.Type<A2, C2>;
  500: t.Type<A3, C3>;
  504: t.Type<A4, C4>;
};

export function GetCartsDecoder<
  A0 = CartRequest,
  C0 = CartRequest,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = ProblemJson,
  C2 = ProblemJson,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = ProblemJson,
  C4 = ProblemJson
>(
  overrideTypes:
    | Partial<GetCartsResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, never>
  | r.IResponseType<400, A1, never>
  | r.IResponseType<404, A2, never>
  | r.IResponseType<500, A3, never>
  | r.IResponseType<504, A4, never>
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getCartsDefaultResponses as unknown) as GetCartsResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, never>(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        never
      >(400, type[400])) as r.ResponseDecoder<r.IResponseType<400, A1, never>>;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, never>(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        never
      >(404, type[404])) as r.ResponseDecoder<r.IResponseType<404, A2, never>>;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, never>(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        never
      >(500, type[500])) as r.ResponseDecoder<r.IResponseType<500, A3, never>>;

  const d504 = (type[504].name === "undefined"
    ? r.constantResponseDecoder<undefined, 504, never>(504, undefined)
    : r.ioResponseDecoder<
        504,
        typeof type[504]["_A"],
        typeof type[504]["_O"],
        never
      >(504, type[504])) as r.ResponseDecoder<r.IResponseType<504, A4, never>>;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(r.composeResponseDecoders(d200, d400), d404),
      d500
    ),
    d504
  );
}

// Decodes the success response with the type defined in the specs
export const GetCartsDefaultDecoder = () => GetCartsDecoder();

/****************************************************************
 * GetCartsRedirect
 */

// Request type definition
export type GetCartsRedirectT = r.IGetApiRequestType<
  { readonly cart_id: string },
  never,
  never,
  r.IResponseType<200, undefined, never>
>;

export const getCartsRedirectDefaultResponses = {
  200: t.undefined
};

export type GetCartsRedirectResponsesT<A0 = undefined, C0 = undefined> = {
  200: t.Type<A0, C0>;
};

export function GetCartsRedirectDecoder<A0 = undefined, C0 = undefined>(
  overrideTypes:
    | Partial<GetCartsRedirectResponsesT<A0, C0>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<r.IResponseType<200, A0, never>> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getCartsRedirectDefaultResponses as unknown) as GetCartsRedirectResponsesT<
      A0,
      C0
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, never>(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        never
      >(200, type[200])) as r.ResponseDecoder<r.IResponseType<200, A0, never>>;

  return d200;
}

// Decodes the success response with the type defined in the specs
export const GetCartsRedirectDefaultDecoder = () => GetCartsRedirectDecoder();

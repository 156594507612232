import * as t from "io-ts";
export const PAYMENT_CHECK_INIT = t.literal("PAYMENT_CHECK_INIT");
export type PAYMENT_CHECK_INIT = t.TypeOf<typeof PAYMENT_CHECK_INIT>;
export const PAYMENT_CHECK_NET_ERR = t.literal("PAYMENT_CHECK_NET_ERR");
export type PAYMENT_CHECK_NET_ERR = t.TypeOf<typeof PAYMENT_CHECK_NET_ERR>;
export const PAYMENT_CHECK_SVR_ERR = t.literal("PAYMENT_CHECK_SVR_ERR");
export type PAYMENT_CHECK_SVR_ERR = t.TypeOf<typeof PAYMENT_CHECK_SVR_ERR>;
export const PAYMENT_CHECK_RESP_ERR = t.literal("PAYMENT_CHECK_RESP_ERR");
export type PAYMENT_CHECK_RESP_ERR = t.TypeOf<typeof PAYMENT_CHECK_RESP_ERR>;
export const PAYMENT_CHECK_SUCCESS = t.literal("PAYMENT_CHECK_SUCCESS");
export type PAYMENT_CHECK_SUCCESS = t.TypeOf<typeof PAYMENT_CHECK_SUCCESS>;
export const PAYMENT_RESOURCES_INIT = t.literal("PAYMENT_RESOURCES_INIT");
export type PAYMENT_RESOURCES_INIT = t.TypeOf<typeof PAYMENT_RESOURCES_INIT>;
export const PAYMENT_RESOURCES_NET_ERR = t.literal("PAYMENT_RESOURCES_NET_ERR");
export type PAYMENT_RESOURCES_NET_ERR = t.TypeOf<
  typeof PAYMENT_RESOURCES_NET_ERR
>;
export const PAYMENT_RESOURCES_SVR_ERR = t.literal("PAYMENT_RESOURCES_SVR_ERR");
export type PAYMENT_RESOURCES_SVR_ERR = t.TypeOf<
  typeof PAYMENT_RESOURCES_SVR_ERR
>;
export const PAYMENT_RESOURCES_RESP_ERR = t.literal(
  "PAYMENT_RESOURCES_RESP_ERR"
);
export type PAYMENT_RESOURCES_RESP_ERR = t.TypeOf<
  typeof PAYMENT_RESOURCES_RESP_ERR
>;
export const PAYMENT_RESOURCES_SUCCESS = t.literal("PAYMENT_RESOURCES_SUCCESS");
export type PAYMENT_RESOURCES_SUCCESS = t.TypeOf<
  typeof PAYMENT_RESOURCES_SUCCESS
>;
export const PAYMENT_START_SESSION_INIT = t.literal(
  "PAYMENT_START_SESSION_INIT"
);
export type PAYMENT_START_SESSION_INIT = t.TypeOf<
  typeof PAYMENT_START_SESSION_INIT
>;
export const PAYMENT_START_SESSION_NET_ERR = t.literal(
  "PAYMENT_START_SESSION_NET_ERR"
);
export type PAYMENT_START_SESSION_NET_ERR = t.TypeOf<
  typeof PAYMENT_START_SESSION_NET_ERR
>;
export const PAYMENT_START_SESSION_SVR_ERR = t.literal(
  "PAYMENT_START_SESSION_SVR_ERR"
);
export type PAYMENT_START_SESSION_SVR_ERR = t.TypeOf<
  typeof PAYMENT_START_SESSION_SVR_ERR
>;
export const PAYMENT_START_SESSION_RESP_ERR = t.literal(
  "PAYMENT_START_SESSION_RESP_ERR"
);
export type PAYMENT_START_SESSION_RESP_ERR = t.TypeOf<
  typeof PAYMENT_START_SESSION_RESP_ERR
>;
export const PAYMENT_START_SESSION_SUCCESS = t.literal(
  "PAYMENT_START_SESSION_SUCCESS"
);
export type PAYMENT_START_SESSION_SUCCESS = t.TypeOf<
  typeof PAYMENT_START_SESSION_SUCCESS
>;
export const PAYMENT_APPROVE_TERMS_INIT = t.literal(
  "PAYMENT_APPROVE_TERMS_INIT"
);
export type PAYMENT_APPROVE_TERMS_INIT = t.TypeOf<
  typeof PAYMENT_APPROVE_TERMS_INIT
>;
export const PAYMENT_APPROVE_TERMS_NET_ERR = t.literal(
  "PAYMENT_APPROVE_TERMS_NET_ERR"
);
export type PAYMENT_APPROVE_TERMS_NET_ERR = t.TypeOf<
  typeof PAYMENT_APPROVE_TERMS_NET_ERR
>;
export const PAYMENT_APPROVE_TERMS_SVR_ERR = t.literal(
  "PAYMENT_APPROVE_TERMS_SVR_ERR"
);
export type PAYMENT_APPROVE_TERMS_SVR_ERR = t.TypeOf<
  typeof PAYMENT_APPROVE_TERMS_SVR_ERR
>;
export const PAYMENT_APPROVE_TERMS_RESP_ERR = t.literal(
  "PAYMENT_APPROVE_TERMS_RESP_ERR"
);
export type PAYMENT_APPROVE_TERMS_RESP_ERR = t.TypeOf<
  typeof PAYMENT_APPROVE_TERMS_RESP_ERR
>;
export const PAYMENT_APPROVE_TERMS_SUCCESS = t.literal(
  "PAYMENT_APPROVE_TERMS_SUCCESS"
);
export type PAYMENT_APPROVE_TERMS_SUCCESS = t.TypeOf<
  typeof PAYMENT_APPROVE_TERMS_SUCCESS
>;
export const PAYMENT_WALLET_INIT = t.literal("PAYMENT_WALLET_INIT");
export type PAYMENT_WALLET_INIT = t.TypeOf<typeof PAYMENT_WALLET_INIT>;
export const PAYMENT_WALLET_NET_ERR = t.literal("PAYMENT_WALLET_NET_ERR");
export type PAYMENT_WALLET_NET_ERR = t.TypeOf<typeof PAYMENT_WALLET_NET_ERR>;
export const PAYMENT_WALLET_SVR_ERR = t.literal("PAYMENT_WALLET_SVR_ERR");
export type PAYMENT_WALLET_SVR_ERR = t.TypeOf<typeof PAYMENT_WALLET_SVR_ERR>;
export const PAYMENT_WALLET_RESP_ERR = t.literal("PAYMENT_WALLET_RESP_ERR");
export type PAYMENT_WALLET_RESP_ERR = t.TypeOf<typeof PAYMENT_WALLET_RESP_ERR>;
export const PAYMENT_WALLET_SUCCESS = t.literal("PAYMENT_WALLET_SUCCESS");
export type PAYMENT_WALLET_SUCCESS = t.TypeOf<typeof PAYMENT_WALLET_SUCCESS>;
export const PAYMENT_PAY3DS2_INIT = t.literal("PAYMENT_PAY3DS2_INIT");
export type PAYMENT_PAY3DS2_INIT = t.TypeOf<typeof PAYMENT_PAY3DS2_INIT>;
export const PAYMENT_PAY3DS2_NET_ERR = t.literal("PAYMENT_PAY3DS2_NET_ERR");
export type PAYMENT_PAY3DS2_NET_ERR = t.TypeOf<typeof PAYMENT_PAY3DS2_NET_ERR>;
export const PAYMENT_PAY3DS2_SVR_ERR = t.literal("PAYMENT_PAY3DS2_SVR_ERR");
export type PAYMENT_PAY3DS2_SVR_ERR = t.TypeOf<typeof PAYMENT_PAY3DS2_SVR_ERR>;
export const PAYMENT_PAY3DS2_RESP_ERR = t.literal("PAYMENT_PAY3DS2_RESP_ERR");
export type PAYMENT_PAY3DS2_RESP_ERR = t.TypeOf<
  typeof PAYMENT_PAY3DS2_RESP_ERR
>;
export const PAYMENT_PAY3DS2_SUCCESS = t.literal("PAYMENT_PAY3DS2_SUCCESS");
export type PAYMENT_PAY3DS2_SUCCESS = t.TypeOf<typeof PAYMENT_PAY3DS2_SUCCESS>;

export const PAYMENT_PSPLIST_INIT = t.literal("PAYMENT_PSPLIST_INIT");
export type PAYMENT_PSPLIST_INIT = t.TypeOf<typeof PAYMENT_PSPLIST_INIT>;
export const PAYMENT_PSPLIST_NET_ERR = t.literal("PAYMENT_PSPLIST_NET_ERR");
export type PAYMENT_PSPLIST_NET_ERR = t.TypeOf<typeof PAYMENT_PSPLIST_NET_ERR>;
export const PAYMENT_PSPLIST_SVR_ERR = t.literal("PAYMENT_PSPLIST_SVR_ERR");
export type PAYMENT_PSPLIST_SVR_ERR = t.TypeOf<typeof PAYMENT_PAY3DS2_SVR_ERR>;
export const PAYMENT_PSPLIST_RESP_ERR = t.literal("PAYMENT_PSPLIST_RESP_ERR");
export type PAYMENT_PSPLIST_RESP_ERR = t.TypeOf<
  typeof PAYMENT_PSPLIST_RESP_ERR
>;
export const PAYMENT_PSPLIST_SUCCESS = t.literal("PAYMENT_PSPLIST_SUCCESS");
export type PAYMENT_PSPLIST_SUCCESS = t.TypeOf<typeof PAYMENT_PSPLIST_SUCCESS>;

export const PAYMENT_UPD_WALLET_INIT = t.literal("PAYMENT_UPD_WALLET_INIT");
export type PAYMENT_UPD_WALLET_INIT = t.TypeOf<typeof PAYMENT_UPD_WALLET_INIT>;
export const PAYMENT_UPD_WALLET_NET_ERR = t.literal(
  "PAYMENT_UPD_WALLET_NET_ERR"
);
export type PAYMENT_UPD_WALLET_NET_ERR = t.TypeOf<
  typeof PAYMENT_UPD_WALLET_NET_ERR
>;
export const PAYMENT_UPD_WALLET_SVR_ERR = t.literal(
  "PAYMENT_UPD_WALLET_SVR_ERR"
);
export type PAYMENT_UPD_WALLET_SVR_ERR = t.TypeOf<
  typeof PAYMENT_UPD_WALLET_SVR_ERR
>;
export const PAYMENT_UPD_WALLET_RESP_ERR = t.literal(
  "PAYMENT_UPD_WALLET_RESP_ERR"
);
export type PAYMENT_UPD_WALLET_RESP_ERR = t.TypeOf<
  typeof PAYMENT_UPD_WALLET_RESP_ERR
>;
export const PAYMENT_UPD_WALLET_SUCCESS = t.literal(
  "PAYMENT_UPD_WALLET_SUCCESS"
);
export type PAYMENT_UPD_WALLET_SUCCESS = t.TypeOf<
  typeof PAYMENT_UPD_WALLET_SUCCESS
>;

export const THREEDSMETHODURL_STEP1_REQ = t.literal(
  "THREEDSMETHODURL_STEP1_REQ"
);
export type THREEDSMETHODURL_STEP1_REQ = t.TypeOf<
  typeof THREEDSMETHODURL_STEP1_REQ
>;
export const THREEDSMETHODURL_STEP1_RESP_ERR = t.literal(
  "THREEDSMETHODURL_STEP1_RESP_ERR"
);
export type THREEDSMETHODURL_STEP1_RESP_ERR = t.TypeOf<
  typeof THREEDSMETHODURL_STEP1_RESP_ERR
>;
export const THREEDSMETHODURL_STEP1_SUCCESS = t.literal(
  "THREEDSMETHODURL_STEP1_SUCCESS"
);
export type THREEDSMETHODURL_STEP1_SUCCESS = t.TypeOf<
  typeof THREEDSMETHODURL_STEP1_SUCCESS
>;
export const TRANSACTION_RESUME3DS2_INIT = t.literal(
  "TRANSACTION_RESUME3DS2_INIT"
);
export type TRANSACTION_RESUME3DS2_INIT = t.TypeOf<
  typeof TRANSACTION_RESUME3DS2_INIT
>;
export const TRANSACTION_RESUME3DS2_NET_ERR = t.literal(
  "TRANSACTION_RESUME3DS2_NET_ERR"
);
export type TRANSACTION_RESUME3DS2_NET_ERR = t.TypeOf<
  typeof TRANSACTION_RESUME3DS2_NET_ERR
>;
export const TRANSACTION_RESUME3DS2_SVR_ERR = t.literal(
  "TRANSACTION_RESUME3DS2_SVR_ERR"
);
export type TRANSACTION_RESUME3DS2_SVR_ERR = t.TypeOf<
  typeof TRANSACTION_RESUME3DS2_SVR_ERR
>;
export const TRANSACTION_RESUME3DS2_RESP_ERR = t.literal(
  "TRANSACTION_RESUME3DS2_RESP_ERR"
);
export type TRANSACTION_RESUME3DS2_RESP_ERR = t.TypeOf<
  typeof TRANSACTION_RESUME3DS2_RESP_ERR
>;
export const TRANSACTION_RESUME3DS2_SUCCESS = t.literal(
  "TRANSACTION_RESUME3DS2_SUCCESS"
);
export type TRANSACTION_RESUME3DS2_SUCCESS = t.TypeOf<
  typeof TRANSACTION_RESUME3DS2_SUCCESS
>;

export const TRANSACTION_POLLING_CHECK_INIT = t.literal(
  "TRANSACTION_POLLING_CHECK_INIT"
);
export type TRANSACTION_POLLING_CHECK_INIT = t.TypeOf<
  typeof TRANSACTION_POLLING_CHECK_INIT
>;
export const TRANSACTION_POLLING_CHECK_NET_ERR = t.literal(
  "TRANSACTION_POLLING_CHECK_NET_ERR"
);
export type TRANSACTION_POLLING_CHECK_NET_ERR = t.TypeOf<
  typeof TRANSACTION_POLLING_CHECK_NET_ERR
>;
export const TRANSACTION_POLLING_CHECK_SVR_ERR = t.literal(
  "TRANSACTION_POLLING_CHECK_SVR_ERR"
);
export type TRANSACTION_POLLING_CHECK_SVR_ERR = t.TypeOf<
  typeof TRANSACTION_POLLING_CHECK_SVR_ERR
>;
export const TRANSACTION_POLLING_CHECK_RESP_ERR = t.literal(
  "TRANSACTION_POLLING_CHECK_RESP_ERR"
);
export type TRANSACTION_POLLING_CHECK_RESP_ERR = t.TypeOf<
  typeof TRANSACTION_POLLING_CHECK_RESP_ERR
>;
export const TRANSACTION_POLLING_CHECK_SUCCESS = t.literal(
  "TRANSACTION_POLLING_CHECK_SUCCESS"
);
export type TRANSACTION_POLLING_CHECK_SUCCESS = t.TypeOf<
  typeof TRANSACTION_POLLING_CHECK_SUCCESS
>;

export const THREEDSACSCHALLENGEURL_STEP2_REQ = t.literal(
  "THREEDSACSCHALLENGEURL_STEP2_REQ"
);
export type THREEDSACSCHALLENGEURL_STEP2_REQ = t.TypeOf<
  typeof THREEDSACSCHALLENGEURL_STEP2_REQ
>;
export const THREEDSACSCHALLENGEURL_STEP2_RESP_ERR = t.literal(
  "THREEDSACSCHALLENGEURL_STEP2_RESP_ERR"
);
export type THREEDSACSCHALLENGEURL_STEP2_RESP_ERR = t.TypeOf<
  typeof THREEDSACSCHALLENGEURL_STEP2_RESP_ERR
>;
export const THREEDSACSCHALLENGEURL_STEP2_SUCCESS = t.literal(
  "THREEDSACSCHALLENGEURL_STEP2_SUCCESS"
);
export type THREEDSACSCHALLENGEURL_STEP2_SUCCESS = t.TypeOf<
  typeof THREEDSACSCHALLENGEURL_STEP2_SUCCESS
>;

export const TRANSACTION_RESUMEXPAY_INIT = t.literal(
  "TRANSACTION_RESUMEXPAY_INIT"
);
export type TRANSACTION_RESUMEXPAY_INIT = t.TypeOf<
  typeof TRANSACTION_RESUME3DS2_INIT
>;

export const TRANSACTION_RESUMEXPAY_NET_ERR = t.literal(
  "TRANSACTION_RESUMEXPAY_NET_ERR"
);
export type TRANSACTION_RESUMEXPAY_NET_ERR = t.TypeOf<
  typeof TRANSACTION_RESUME3DS2_NET_ERR
>;

export const TRANSACTION_RESUMEXPAY_SVR_ERR = t.literal(
  "TRANSACTION_RESUMEXPAY_SVR_ERR"
);
export type TRANSACTION_RESUMEXPAY_SVR_ERR = t.TypeOf<
  typeof TRANSACTION_RESUME3DS2_SVR_ERR
>;

export const TRANSACTION_RESUMEXPAY_RESP_ERR = t.literal(
  "TRANSACTION_RESUMEXPAY_RESP_ERR"
);
export type TRANSACTION_RESUMEXPAY_RESP_ERR = t.TypeOf<
  typeof TRANSACTION_RESUME3DS2_RESP_ERR
>;

export const TRANSACTION_RESUMEXPAY_SUCCESS = t.literal(
  "TRANSACTION_RESUMEXPAY_SUCCESS"
);
export type TRANSACTION_RESUMEXPAY_SUCCESS = t.TypeOf<
  typeof TRANSACTION_RESUME3DS2_SUCCESS
>;

export const THREEDS_CHECK_XPAY_RESP_ERR = t.literal(
  "THREEDS_CHECK_XPAY_RESP_ERR"
);
export type THREEDS_CHECK_XPAY_RESP_ERR = t.TypeOf<
  typeof THREEDS_CHECK_XPAY_RESP_ERR
>;

export const THREEDS_CHECK_XPAY_RESP_SUCCESS = t.literal(
  "THREEDS_CHECK_XPAY_RESP_SUCCESS"
);
export type THREEDS_CHECK_XPAY_RESP_SUCCESS = t.TypeOf<
  typeof THREEDS_CHECK_XPAY_RESP_SUCCESS
>;

export const PAYMENT_OUTCOME_CODE = t.literal("PAYMENT_OUTCOME_CODE");
export type PAYMENT_OUTCOME_CODE = t.TypeOf<typeof PAYMENT_OUTCOME_CODE>;

export const PAYMENT_ACTION_DELETE_INIT = t.literal(
  "PAYMENT_ACTION_DELETE_INIT"
);
export type PAYMENT_ACTION_DELETE_INIT = t.TypeOf<
  typeof PAYMENT_ACTION_DELETE_INIT
>;
export const PAYMENT_ACTION_DELETE_NET_ERR = t.literal(
  "PAYMENT_ACTION_DELETE_NET_ERR"
);
export type PAYMENT_ACTION_DELETE_NET_ERR = t.TypeOf<
  typeof PAYMENT_ACTION_DELETE_NET_ERR
>;
export const PAYMENT_ACTION_DELETE_SVR_ERR = t.literal(
  "PAYMENT_ACTION_DELETE_SVR_ERR"
);
export type PAYMENT_ACTION_DELETE_SVR_ERR = t.TypeOf<
  typeof PAYMENT_ACTION_DELETE_SVR_ERR
>;
export const PAYMENT_ACTION_DELETE_RESP_ERR = t.literal(
  "PAYMENT_ACTION_DELETE_RESP_ERR"
);
export type PAYMENT_ACTION_DELETE_RESP_ERR = t.TypeOf<
  typeof PAYMENT_ACTION_DELETE_RESP_ERR
>;
export const PAYMENT_ACTION_DELETE_SUCCESS = t.literal(
  "PAYMENT_ACTION_DELETE_SUCCESS"
);
export type PAYMENT_ACTION_DELETE_SUCCESS = t.TypeOf<
  typeof PAYMENT_ACTION_DELETE_SUCCESS
>;
export const PAYMENT_VERIFY_INIT = t.literal("PAYMENT_VERIFY_INIT");
export type PAYMENT_VERIFY_INIT = t.TypeOf<typeof PAYMENT_VERIFY_INIT>;
export const PAYMENT_VERIFY_NET_ERR = t.literal("PAYMENT_VERIFY_NET_ERR");
export type PAYMENT_VERIFY_NET_ERR = t.TypeOf<typeof PAYMENT_VERIFY_NET_ERR>;
export const PAYMENT_VERIFY_SVR_ERR = t.literal("PAYMENT_VERIFY_SVR_ERR");
export type PAYMENT_VERIFY_SVR_ERR = t.TypeOf<typeof PAYMENT_VERIFY_SVR_ERR>;
export const PAYMENT_VERIFY_RESP_ERR = t.literal("PAYMENT_VERIFY_RESP_ERR");
export type PAYMENT_VERIFY_RESP_ERR = t.TypeOf<typeof PAYMENT_VERIFY_RESP_ERR>;
export const PAYMENT_VERIFY_SUCCESS = t.literal("PAYMENT_VERIFY_SUCCESS");
export type PAYMENT_VERIFY_SUCCESS = t.TypeOf<typeof PAYMENT_VERIFY_SUCCESS>;

export const PAYMENT_ACTIVATE_INIT = t.literal("PAYMENT_ACTIVATE_INIT");
export type PAYMENT_ACTIVATE_INIT = t.TypeOf<typeof PAYMENT_ACTIVATE_INIT>;
export const PAYMENT_ACTIVATE_NET_ERR = t.literal("PAYMENT_ACTIVATE_NET_ERR");
export type PAYMENT_ACTIVATE_NET_ERR = t.TypeOf<
  typeof PAYMENT_ACTIVATE_NET_ERR
>;
export const PAYMENT_ACTIVATE_SVR_ERR = t.literal("PAYMENT_ACTIVATE_SVR_ERR");
export type PAYMENT_ACTIVATE_SVR_ERR = t.TypeOf<
  typeof PAYMENT_ACTIVATE_SVR_ERR
>;
export const PAYMENT_ACTIVATE_RESP_ERR = t.literal("PAYMENT_ACTIVATE_RESP_ERR");
export type PAYMENT_ACTIVATE_RESP_ERR = t.TypeOf<
  typeof PAYMENT_ACTIVATE_RESP_ERR
>;
export const PAYMENT_ACTIVATE_SUCCESS = t.literal("PAYMENT_ACTIVATE_SUCCESS");
export type PAYMENT_ACTIVATE_SUCCESS = t.TypeOf<
  typeof PAYMENT_ACTIVATE_SUCCESS
>;

export const PAYMENT_ACTIVATION_STATUS_INIT = t.literal(
  "PAYMENT_ACTIVATION_STATUS_INIT"
);
export type PAYMENT_ACTIVATION_STATUS_INIT = t.TypeOf<
  typeof PAYMENT_ACTIVATION_STATUS_INIT
>;
export const PAYMENT_ACTIVATION_STATUS_NET_ERR = t.literal(
  "PAYMENT_ACTIVATION_STATUS_NET_ERR"
);
export type PAYMENT_ACTIVATION_STATUS_NET_ERR = t.TypeOf<
  typeof PAYMENT_ACTIVATION_STATUS_NET_ERR
>;
export const PAYMENT_ACTIVATION_STATUS_SVR_ERR = t.literal(
  "PAYMENT_ACTIVATION_STATUS_SVR_ERR"
);
export type PAYMENT_ACTIVATION_STATUS_SVR_ERR = t.TypeOf<
  typeof PAYMENT_ACTIVATION_STATUS_SVR_ERR
>;
export const PAYMENT_ACTIVATION_STATUS_RESP_ERR = t.literal(
  "PAYMENT_ACTIVATION_STATUS_RESP_ERR"
);
export type PAYMENT_ACTIVATION_STATUS_RESP_ERR = t.TypeOf<
  typeof PAYMENT_ACTIVATION_STATUS_RESP_ERR
>;
export const PAYMENT_ACTIVATION_STATUS_SUCCESS = t.literal(
  "PAYMENT_ACTIVATION_STATUS_SUCCESS"
);
export type PAYMENT_ACTIVATION_STATUS_SUCCESS = t.TypeOf<
  typeof PAYMENT_ACTIVATION_STATUS_SUCCESS
>;
export const DONATION_INIT_SESSION = t.literal("DONATION_INIT_SESSION");
export type DONATION_INIT_SESSION = t.TypeOf<typeof DONATION_INIT_SESSION>;
export const DONATION_LIST_SUCCESS = t.literal("DONATION_LIST_SUCCESS");
export type DONATION_LIST_SUCCESS = t.TypeOf<typeof DONATION_LIST_SUCCESS>;
export const DONATION_LIST_ERROR = t.literal("DONATION_LIST_ERROR");
export type DONATION_LIST_ERROR = t.TypeOf<typeof DONATION_LIST_ERROR>;
export const DONATION_URL_VISIT = t.literal("DONATION_URL_VISIT");
export type DONATION_URL_VISIT = t.TypeOf<typeof DONATION_URL_VISIT>;
export const DONATION_APPIO_VISIT = t.literal("DONATION_APPIO_VISIT");
export type DONATION_APPIO_VISIT = t.TypeOf<typeof DONATION_APPIO_VISIT>;

export const QRCODE_READER_ACCESS = t.literal("QRCODE_READER_ACCESS");
export type QRCODE_READER_ACCESS = t.TypeOf<typeof QRCODE_READER_ACCESS>;
export const QRCODE_READ_SUCCESS = t.literal("QRCODE_READ_SUCCESS");
export type QRCODE_READ_SUCCESS = t.TypeOf<typeof QRCODE_READ_SUCCESS>;
export const QRCODE_READ_ERROR = t.literal("QRCODE_READ_ERROR");
export type QRCODE_READ_ERROR = t.TypeOf<typeof QRCODE_READ_ERROR>;

export const PAYMENT_METHODS_ACCESS = t.literal("PAYMENT_METHODS_ACCESS");
export type PAYMENT_METHODS_ACCESS = t.TypeOf<typeof PAYMENT_METHODS_ACCESS>;
export const PAYMENT_METHODS_SUCCESS = t.literal("PAYMENT_METHODS_SUCCESS");
export type PAYMENT_METHODS_SUCCESS = t.TypeOf<typeof PAYMENT_METHODS_SUCCESS>;
export const PAYMENT_METHODS_RESP_ERROR = t.literal(
  "PAYMENT_METHODS_RESP_ERROR"
);
export type PAYMENT_METHODS_RESP_ERROR = t.TypeOf<
  typeof PAYMENT_METHODS_RESP_ERROR
>;
export const PAYMENT_METHODS_NET_ERROR = t.literal("PAYMENT_METHODS_NET_ERROR");
export type PAYMENT_METHODS_NET_ERROR = t.TypeOf<
  typeof PAYMENT_METHODS_NET_ERROR
>;
export const PAYMENT_METHODS_SVR_ERROR = t.literal("PAYMENT_METHODS_SVR_ERROR");
export type PAYMENT_METHODS_SVR_ERROR = t.TypeOf<
  typeof PAYMENT_METHODS_SVR_ERROR
>;
export const PAYMENT_METHODS_CHOICE = t.literal("PAYMENT_METHODS_CHOICE");
export type PAYMENT_METHODS_CHOICE = t.TypeOf<typeof PAYMENT_METHODS_CHOICE>;

export const CART_REQUEST_ACCESS = t.literal("CART_REQUEST_ACCESS");
export type CART_REQUEST_ACCESS = t.TypeOf<typeof CART_REQUEST_ACCESS>;
export const CART_REQUEST_SUCCESS = t.literal("CART_REQUEST_SUCCESS");
export type CART_REQUEST_SUCCESS = t.TypeOf<typeof CART_REQUEST_SUCCESS>;
export const CART_REQUEST_RESP_ERROR = t.literal("CART_REQUEST_RESP_ERROR");
export type CART_REQUEST_RESP_ERROR = t.TypeOf<typeof CART_REQUEST_RESP_ERROR>;
export const CART_REQUEST_NET_ERROR = t.literal("CART_REQUEST_NET_ERROR");
export type CART_REQUEST_NET_ERROR = t.TypeOf<typeof CART_REQUEST_NET_ERROR>;
export const CART_REQUEST_SVR_ERROR = t.literal("CART_REQUEST_SVR_ERROR");
export type CART_REQUEST_SVR_ERROR = t.TypeOf<typeof CART_REQUEST_SVR_ERROR>;

export const TRANSACTION_AUTH_INIT = t.literal("TRANSACTION_AUTH_INIT");
export type TRANSACTION_AUTH_INIT = t.TypeOf<typeof TRANSACTION_AUTH_INIT>;
export const TRANSACTION_AUTH_SRV_ERROR = t.literal(
  "TRANSACTION_AUTH_SRV_ERROR"
);
export type TRANSACTION_AUTH_SRV_ERROR = t.TypeOf<
  typeof TRANSACTION_AUTH_SRV_ERROR
>;
export const TRANSACTION_AUTH_NET_ERROR = t.literal(
  "TRANSACTION_AUTH_NET_ERROR"
);
export type TRANSACTION_AUTH_NET_ERROR = t.TypeOf<
  typeof TRANSACTION_AUTH_NET_ERROR
>;
export const TRANSACTION_AUTH_SUCCES = t.literal("TRANSACTION_AUTH_SUCCES");
export type TRANSACTION_AUTH_SUCCES = t.TypeOf<typeof TRANSACTION_AUTH_SUCCES>;
export const TRANSACTION_AUTH_RESP_ERROR = t.literal(
  "TRANSACTION_AUTH_RESP_ERROR"
);
export type TRANSACTION_AUTH_RESP_ERROR = t.TypeOf<
  typeof TRANSACTION_AUTH_RESP_ERROR
>;

export const VOC_USER_EXIT = t.literal("VOC_USER_EXIT");
export type VOC_USER_EXIT = t.TypeOf<typeof VOC_USER_EXIT>;

export const NPG_INIT = t.literal("NPG_INIT");
export type NPG_INIT = t.TypeOf<typeof NPG_INIT>;
export const NPG_NET_ERR = t.literal("NPG_NET_ERR");
export type NPG_NET_ERR = t.TypeOf<typeof NPG_NET_ERR>;
export const NPG_SVR_ERR = t.literal("NPG_SVR_ERR");
export type NPG_SVR_ERR = t.TypeOf<typeof NPG_SVR_ERR>;
export const NPG_RESP_ERROR = t.literal("NPG_RESP_ERROR");
export type NPG_RESP_ERROR = t.TypeOf<typeof NPG_RESP_ERROR>;
export const NPG_SUCCESS = t.literal("NPG_SUCCESS");
export type NPG_SUCCESS = t.TypeOf<typeof NPG_SUCCESS>;

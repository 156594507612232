export enum ErrorsType {
  CONNECTION = "CONNECTION",
  SERVER = "SERVER",
  GENERIC_ERROR = "GENERIC_ERROR",
  AUTH_ERROR = "AUTH_ERROR",
  INVALID_DATA = "INVALID_DATA",
  TIMEOUT = "TIMEOUT",
  INVALID_CARD = "INVALID_CARD",
  CANCELLED_BY_USER = "CANCELLED_BY_USER",
  EXCESSIVE_AMOUNT = "EXCESSIVE_AMOUNT",
  POLLING_SLOW = "POLLING_SLOW",
  STATUS_ERROR = "STATUS_ERROR",
  INVALID_QRCODE = "INVALID_QRCODE",
  DONATIONLIST_ERROR = "DONATIONLIST_ERROR",
  ECOMMERCE_ERROR = "ECOMMERCE_ERROR",
  INVALID_DECODE = "INVALID_DECODE",
}
